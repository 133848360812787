exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ch-declarations-js": () => import("./../../../src/pages/ch-declarations.js" /* webpackChunkName: "component---src-pages-ch-declarations-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-protection-js": () => import("./../../../src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-fiskal-services-js": () => import("./../../../src/pages/fiskalServices.js" /* webpackChunkName: "component---src-pages-fiskal-services-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-bd-js": () => import("./../../../src/pages/jobs/bd.js" /* webpackChunkName: "component---src-pages-jobs-bd-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-personal-data-js": () => import("./../../../src/pages/personal-data.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-the-platform-documents-js": () => import("./../../../src/pages/thePlatform-documents.js" /* webpackChunkName: "component---src-pages-the-platform-documents-js" */),
  "component---src-pages-the-platform-events-js": () => import("./../../../src/pages/thePlatform-events.js" /* webpackChunkName: "component---src-pages-the-platform-events-js" */),
  "component---src-pages-the-platform-general-js": () => import("./../../../src/pages/thePlatform-general.js" /* webpackChunkName: "component---src-pages-the-platform-general-js" */),
  "component---src-pages-the-platform-js": () => import("./../../../src/pages/thePlatform.js" /* webpackChunkName: "component---src-pages-the-platform-js" */)
}

