export const borderRadius = "16px";

export const theme = {
  global: {
    accordion: {
      heading: {
        level: 3,
        margin: { vertical: "6px", horizontal: "24px" },
      },
      hover: {
        heading: {
          color: "accent-2",
        },
        background: "background-contrast",
      },
      // icons: {
      //   collapse: SubtractCircle,
      //   expand: AddCircle,
      //   color: "hotpink",
      // },
      border: undefined,
    },
    control: {
      border: {
        radius: borderRadius,
      },
    },
    focus: { border: { color: "transparent" } },
    shadows: {
      float: "1px 1px 5px 0 rgb(0 0 0 / 10%)",
      whiteBox: "rgba(43, 83, 135, 0.08) 0px 3px 8px 0px",
      purpleFloat: "5px 10px 25px rgba(225, 90, 120, 0.5)",
      terminal: "rgb(7 7 7 / 48%) 0px 8px 26px 3px",
      redFloat: "5px 10px 25px rgba(225, 90, 120, 0.5)",
      redFloatSmall: "5px 10px 25px rgba(225, 90, 120, 0.3)",
    },
    gradients: {
      linearRed:
        "linear-gradient(265.54deg, #FF9DB1 0%, #F15F7C 26.04%, #342550 99.68%, #1C1D4A 129.42%)",
      linearPurple:
        "linear-gradient(265.54deg, #FF9DB1 0%, #F15F7C 26.04%, #342550 99.68%, #1C1D4A 129.42%)",
      whiteToGray:
        "linear-gradient(rgb(255, 255, 255) 0%, rgb(243, 246, 248) 50%, rgb(243, 246, 248) 100%)",
    },
    colors: {
      brand: "#FF5D79",
      brandPurple: "#1D1D4B",
      brandPurpleLight: "#604DFF",
      "gray-1": "#FAFAFE",
      "gray-2": "#F3F4FB",
      "gray-3": "#EBEBF5",
      "gray-4": "#C8C8D4",
      "gray-5": "#9596af",
      "accent-1": "#2ADCC1",
      "accent-1-dark": "#5F54BF",
      "accent-2": "#B5ACFF",
      "accent-2-light": "rgba(181, 172, 255, 0.2)",
      "accent-3": "#E7E4FF",
      "accent-4": "#F45B69",
      "light-1": "#F4F4F4",
      "light-2": "#F2F2F2",
      "light-3": "#EDEDED",
      "light-4": "#DADADA",
      "dark-1": "#333333",
      "dark-2": "#555555",
      "dark-3": "#9596af",
      "dark-4": "#474A57",
      "dark-5": "#999999",
      "background-dark": "#090749",
      "bg-gray": "#FBFBFF",
      "title-gray": "#383b56",
      footerGray: "#9596af",
      footerBackground: "#181925",
      16: "#7163FA",
      15: "#7862F3",
      14: "#8162EB",
      13: "#8A62E3",
      12: "#9161DC",
      11: "#9A61D4",
      10: "#A360CC",
      9: "#AB60C4",
      8: "#B460BC",
      7: "#BB5FB5",
      6: "#C45FAD",
      5: "#CC5FA5",
      4: "#D55E9D",
      3: "#DD5E95",
      2: "#E65E8D",
      1: "#EF5D85",
      0: "#F75D7E",
    },
    font: {
      family: "Manrope",
      style: "sans-serif",
    },
    breakpoints: {
      small: {
        value: 568,
        edgeSize: {
          none: "0px",
          small: "6px",
          medium: "12px",
          large: "24px",
        },
      },
      medium: {
        value: 768,
        edgeSize: {
          none: "0px",
          small: "12px",
          medium: "24px",
          large: "48px",
        },
      },
      large: {
        value: 1024,
        edgeSize: {
          none: "0px",
          small: "12px",
          medium: "24px",
          large: "48px",
        },
      },
    },
  },
  button: {
    border: { radius: borderRadius },
    color: "#4700DF",
  },
  card: {
    container: {
      round: borderRadius,
    },
  },
  paragraph: {
    small: {
      size: "14px",
      height: "20px",
      maxWidth: "336px",
    },
    medium: {
      size: "15px",
      height: "24px",
      maxWidth: "432px",
    },
    large: {
      size: "16px",
      height: "28px",
      maxWidth: "528px",
    },
  },
  textArea: {
    extend: {
      background: "#FAFAFE",
      fontSize: "15px",
      fontWeight: "400",
      color: "black",
    },
  },
  select: {
    extend: {},
  },
  textInput: {
    extend: {
      background: "#FAFAFE",
      fontSize: "15px",
      fontWeight: "400",
      color: "black",
    },
    container: {
      extend: {
        borderRadius: "4px",
      },
    },
  },
  heading: {
    level: {
      1: {
        medium: {
          size: "40x",
          height: "38px",
          maxWidth: "602px",
        },
        large: {
          size: "44px",
          height: "38px",
          maxWidth: "602px",
        },
        xlarge: {
          size: "48px",
          height: "38px",
          maxWidth: "602px",
        },
      },
      2: {
        font: {},
        large: {
          size: "34px",
          height: "38px",
          maxWidth: "602px",
        },
        xlarge: {
          size: "34px",
          height: "38px",
          maxWidth: "602px",
        },
      },
      3: {},
      4: {
        font: {},
      },
      6: {
        small: {
          size: "12px",
          height: "18px",
          maxWidth: "336px",
        },
        medium: {
          size: "14px",
          height: "18px",
          maxWidth: "336px",
        },
        large: {
          size: "14px",
          height: "20px",
          maxWidth: "336px",
        },
        xlarge: {
          size: "14px",
          height: "20px",
          maxWidth: "336px",
        },
      },
    },
  },
};
